<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">تعديل بيانات الحفلة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                        <form @submit="UpdateStudent" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم الحفلة<span class="red">*</span>
                    <span color='danger' class="error">{{errors.full_name?errors.full_name[0]:''}}</span></label>
                  <vmd-input
                    id=""
                    type="text"
                    name="البريد الإلكتروني"
                   v-model='event.name'
                   placeholder='اسم الحفلة'
                   :invalidFeedback="errors.full_name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة للعميل<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" ادخل السعر"
                   v-model='event.client_price'
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة للمرافق<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" ادخل السعر"
                   v-model='event.companion_price'
                    required
                  />
                </div>

                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <router-link to="/events">
                  <vmd-button
                    class="my-4 mb-2"
                    type='reset'
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                  </router-link>
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
export default {
  name: 'EditStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      event: {},
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}events/${this.$route.params.id}`).then(response => {
      this.event = response.data.data
    })
  },
  methods: {
    async UpdateStudent (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.event)) {
        swal('اسم الحفلة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.event.name)
      formData.append('client_price', this.event.client_price)
      formData.append('companion_price', this.event.companion_price)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'events/' + this.$route.params.id, '/events')
    }
  }
}
</script>
